//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ApproveTimeLine from "./approveTimeLine";

export default {
  name: "ExpenseApplyInfo",
  data() {
    return {
      activities: [
        {
          content: "默认部门",
          timestamp: "发起出差时间： 2020-06-18 12:2:15",
          color: "#58CA18",
          icon: "el-icon-check"
        },
        {
          content: "审批同意",
          color: "#ff8c4d",
          timestamp: "审核中...",
          icon: "el-icon-more"
        },
        {
          content: "预定机票、火车票、酒店",
          timestamp: "审核中...",
          icon: "el-icon-more"
        },
        {
          content: "默认样式的节点",
          timestamp: "审核中...",
          icon: "el-icon-more"
        }
      ]
    };
  },
  components: {
    ApproveTimeLine
  },
  props: {
    isShowFn: Function
  },
  methods: {},
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
