//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Dialog from "@/component/dialog";
import AddCost from "../../components/addCost";
import SelectApprover from "../../components/selectApprover";
import ExpenseApplyInfo from "../../components/expenseApplyInfo";
export default {
  name: "ExpenseApply",
  data() {
    return {
      expenseInfoShow: true,
      peopleList: [],
      newPeopleList: [],
      approverSelectList: [],
      isShow: false,
      addCostShow: false
    };
  },
  components: {
    SelectApprover,
    Dialog,
    ExpenseApplyInfo,
    AddCost
  },
  methods: {
    approver(text, item) {
      console.log(item);
      //   item ? (this.approverId = item.relevanceId) : "";
      //   this.approverType = text;
      this.isShow = true;
    },
    submit() {},
    addCostFn() {
      this.addCostShow = true;
    },
    delCost() {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {})
        .catch(() => {});
    },
    setExpenseShow() {
      this.expenseInfoShow = !this.expenseInfoShow;
    },
    cancel() {
      this.isShow = false;
    }
  },
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
