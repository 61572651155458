//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Upload from "./uploadAccessory";
import Dialog from "@/component/dialog";

export default {
  name: "AddCost",
  data() {
    return {
      formData: {},
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        },
        {
          value: "选项3",
          label: "蚵仔煎"
        },
        {
          value: "选项4",
          label: "龙须面"
        },
        {
          value: "选项5",
          label: "北京烤鸭"
        }
      ],
      value: ""
    };
  },
  props: {
    dialogShow: Boolean
  },
  components: {
    Upload,
    Dialog
  },
  methods: {
    cancel() {
      this.$emit("update:dialogShow", false);
    }
  },
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
